import React, { Component } from 'react';
import CF from '../components/commonfunctions.js';
import { navigate } from 'gatsby';
import Layout from '../layout/layout'
import ScaleLoader from "react-spinners/ScaleLoader";

class Login extends Component {
    state = {
        email: '',
        password: "",
        error: '',
    }

    onTextChanged(e) {
        let { value, name } = e && e.target && e.target;
        let { email, password } = this.state;
        if (name === "email") email = value;
        if (name === "password") password = value;
        this.setState({ email, password })
    }

    validateForm() {
        let { email, password } = this.state
        let error = '';
        let formIsValid = true
        if (!email || email.trim() === '') {
            formIsValid = false
            error = "Authentication failed"
        }
        if (!password || password.trim() === '') {
            formIsValid = false
            error = "Authentication failed"
        }
        this.setState({ error })
        return formIsValid
    }
    onEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.login();
        }
    }
    login = async () => {
        let wordpress = `${process.env.WORDPRESS_BASEURL}/api/user/generate_auth_cookie/'`;
        let { email, password } = this.state;
        if (this.validateForm()) {
            const data = new FormData();
            data.append('username', email);
            data.append('password', password);
            // data.append('insecure', 'cool');
            let response = await CF.callApi(wordpress, data, 'post', true);
            // console.log('response', response);
            if (response && response.data && response.data.status === 'ok') {
                CF.showSuccessMessage(`${response && response.data && response.data.user && response.data.user.firstname ? response.data.user.firstname : ""}${' logged in Successfully'}`);
                CF.setItem('email', response.data && response.data.user && response.data.user.email);
                CF.setItem('displayname', response.data && response.data.user && response.data.user.displayname);
                CF.setItem('token', response.data && response.data.user && response.data.cookie);
                this.setState({ email: '', password: '', errors: {} }, () => navigate('/'));
            }
            else if (response && response.data && response.data.status === "error") {
                this.setState({ error: "Authentication failed" })
            }
        }
    }

    render() {
        let { email, password, error } = this.state;
        return (<React.Fragment>
            <Layout seoTitle={'login'} seoDes={"x-musix "} location={'login'}>
                <div className="login-bg">
                    <div className="login-bg-content">
                        <div className="form-box">
                            <h5>Welcome to The UM-X Suite</h5>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="email">Email<sup>*</sup></label>
                                    <input
                                        className="form-control form-control-second"
                                        type="text"
                                        placeholder="Enter Email "
                                        id="email"
                                        name="email"
                                        onKeyDown={(e) => this.onEnter(e)}
                                        onChange={(e) => this.onTextChanged(e)}
                                        value={email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password<sup>*</sup></label>
                                    <input
                                        type="password"
                                        placeholder="Enter Password"
                                        className="form-control form-control-second"
                                        id="password"
                                        name="password"
                                        onKeyDown={(e) => this.onEnter(e)}
                                        onChange={(e) => this.onTextChanged(e)}
                                        value={password}
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-green"
                                        type="button"
                                        onClick={this.login}
                                    ><span>Submit</span></button>
                                </div>
                            </form>
                        </div>
                        {error ? <div className="error-message">
                            <span>Authentication failed </span>
                            <button className="request-access-btn" onClick={() => this.setState({ error: '' })}>ok</button>
                        </div> : ""}
                    </div>
                </div>


            </Layout>
        </React.Fragment>);
    }
}

export default Login;
